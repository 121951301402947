import "./src/styles/global.css";

import { GatsbyBrowser } from "gatsby";

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  const path = location.pathname;
  const host = window.location.hostname;

  if (host.includes("speakeasyco.com") && path.startsWith("/track")) {
    const queryString = location.search;
    window.location.replace(
      `https://tracking.flaviar.com${path}${queryString}`
    );
  }
};
